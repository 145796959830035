
import type { ElTree } from 'element-plus';
import { parsePermissions2TreeOptions } from '../utils';
import { PartialRole, Role } from '@/interfaces/Role';
import { usePermissions } from '@/composables/api';
import { ref, computed } from '@vue/reactivity';
import { PropType, watch, nextTick } from 'vue';

export default {
  props: {
    row: {
      type: Object as PropType<Role>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { data } = usePermissions();
    const treeRef = ref<InstanceType<typeof ElTree>>();
    const checkedKeys = ref([]);
    const getCheckedKeys = () => {
      // Index of Groups are bigger than 10,000, yet it's not necessary for the request
      checkedKeys.value = treeRef.value.getCheckedKeys(false).filter(p => p < 10000) as number[];
      emit('change', checkedKeys.value);
    };

    watch(data, async() => {
      await nextTick();

      getCheckedKeys();
    });

    const permissionsOptions = computed(() => parsePermissions2TreeOptions(data.value?.data, props.disabled));
    const defaultProps = {
      children: 'children',
      label: 'label'
    };

    return {
      permissionsOptions,
      defaultProps,
      getCheckedKeys,
      treeRef
    };
  }
};
