import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createBlock(_component_el_tree, {
    ref: "treeRef",
    "node-key": "id",
    data: $setup.permissionsOptions,
    props: $setup.defaultProps,
    "check-on-click-node": "",
    "show-checkbox": "",
    "highlight-current": "",
    onCheckChange: $setup.getCheckedKeys
  }, null, 8, ["data", "props", "onCheckChange"]))
}