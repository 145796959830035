import { PartialPermissions } from '@/interfaces/Permissions';

export function parsePermissions2TreeOptions(permissions: PartialPermissions[] = [], disabled?: Boolean) {
    interface TreeNode {
        id: number
        label: string
        disabled?: Boolean
        children?: TreeNode[]
      }

    const options: TreeNode[] = [];
    permissions.forEach((p, index) => {
      // check if menu exist in options label
      const target = options.find(o => o.label === p.menu);
      if (target) {
        // true, push target into children
        target.children.push({
          id: p.id,
          label: p.title,
          disabled: disabled
        });
        return;
      }

      // if not, push option
      options.push({
        id: 10000 + index,
        label: p.menu,
        disabled: disabled,
        children: [{
          id: p.id,
          label: p.title,
          disabled: disabled
        }]
      });
    });
    return options;
}

export function parsePermissions2GroupingSelectOptions(permissions: PartialPermissions[] = []) {
  interface Options{
    value: number
    label: string
  }

  interface GroupingSelectOptions {
  label: string
  options: Options[]
}

  const options: GroupingSelectOptions[] = [];
  permissions.forEach((p, index) => {
  // check if menu exist in options label
    const target = options.find(o => o.label === p.menu);
    if (target) {
    // true, push target into children
      target.options.push({
        value: p.id,
        label: p.title
      });
      return;
    }

    // if not, push option
    options.push({
      label: p.menu,
      options: [{
        value: p.id,
        label: p.title
      }]
    });
  });
  return options;
}
